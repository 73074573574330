import React from "react";

import { sendGtagEvent } from "frontend/js/helpers";

const ListProductsCTA = ({ section, isMobile }) => {
  function handleClick(e) {
    e.preventDefault();

    sendGtagEvent(
      'select_promotion',
      section?.id,
      section?.promotional_name,
      'product_list',
      section?.cta_url
    );
  }

  return (
    <div className={"product-list__cta flex gap-[8px] items-center"}>
      {section.cta_button_text && (
        <>
          <a
            href={`${section.cta_url}`}
            className={`${isMobile ? `b3` : `b2`} button-${section.cta_style} flex gap-x-[8px]`}
            onClick={(e) => handleClick(e)}
          >
            {section.cta_button_text}
          </a>
          <i className={"icon icon-curved-arrow-up-right"}></i>
        </>
      )}
    </div>
  );
};

export default ListProductsCTA;
