import React, { useEffect } from "react";

import ListProductsDefault from "./Layouts/ListProductsDefault";
import ListProductsTabbed from "./Layouts/ListProductsTabbed";
import ListProductsScrollable from "./Layouts/ListProductsScrollable";
import ListProductsGrid from "./Layouts/ListProductsGrid";

import './ListProducts.scss';
import { sendGtagEvent } from "frontend/js/helpers";

const layouts = {
    'list-tabbed': ListProductsTabbed,
    'list-default': ListProductsDefault,
    'list-scrollable': ListProductsScrollable,
    'list-grid': ListProductsGrid
}

let hasLoaded = false;

export const ListProducts = (props) => {
    const LayoutComponent = layouts[props.settings?.section.layout_type] || ListProductsDefault;

    useEffect(() => {
        if (hasLoaded) return

        hasLoaded = true;

        sendGtagEvent(
            'view_promotion',
            props.settings.id,
            props.settings.section.promotional_name,
            'product_list'
        )

        props.settings.section.id = props.settings.id;
    }, []);

    return <LayoutComponent {...props} />
};
